import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { processFile } from './api'; // Adjust the import path as needed
import './ProcessingPage.css'; // Import the updated CSS

const ProcessingPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const processData = async () => {
            const fileId = localStorage.getItem('fileId'); // Retrieve fileId from localStorage

            if (!fileId) {
                console.error('No fileId found in localStorage.');
                navigate('/upload'); // Redirect to UploadPage if fileId is missing
                return;
            }

            try {
                const result = await processFile(fileId); // Call the API function to process the file
                // Redirect to results page after processing is complete
                navigate('/results');
            } catch (error) {
                console.error('Error processing file:', error);
                // Display an error message or handle the error as needed
            }
        };

        processData();
    }, [navigate]);

    return (
        <div className="processing-page-body">
            <div className="processing-page-container">
                <h1 className="processing-page-heading">Analyzing Your Data...</h1>
                <div className="processing-page-spinner"></div>
                <p className="processing-page-paragraph">
                    We are analyzing your spreadsheet to extract valuable insights.
                </p>
            </div>
        </div>
    );
};

export default ProcessingPage;
