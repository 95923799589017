// Chart Component
import React, { useState, useEffect, useCallback } from 'react';
import { Line, Scatter } from 'react-chartjs-2';
import { Range } from 'react-range';
import './Chart.css';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import ReactMarkdown from 'react-markdown';

// Hint message
const HintMarkdown = `
> **Hint:** Use the range slider above to select specific rows from your dataset. The leftmost position shows the earliest date, while the rightmost shows the latest. Adjust it to zoom in on trends you want to explore!
`;

// Scatter plot explanation message
const ScatterExplanationMarkdown = `
> **Understanding the Scatter Plot:** A scatter plot displays individual data points on a two-dimensional graph, where each point represents a pair of values from your dataset. The **x-axis** typically represents one variable, while the **y-axis** represents another. To interpret correlations, look for patterns: 
> - **Positive Correlation:** Points trend upward, indicating that as one variable increases, so does the other.
> - **Negative Correlation:** Points trend downward, showing that as one variable increases, the other decreases.
> - **No Correlation:** Points are scattered randomly, indicating no discernible relationship between the variables. 
>
> Use this information to analyze how different aspects of your dataset relate to one another!
`;

// Line plot explanation message
const LinePlotExplanationMarkdown = `
> **Understanding the Line Plot:** A line plot connects individual data points with straight lines, providing a clear visual representation of how both datasets evolve over time. In this context:
> - Each point represents a value from your datasets, and the lines illustrate trends in these values as they change.
> - **Positive Correlation:** If the lines for both datasets move in the same direction (both up or both down), it indicates a positive correlation, suggesting that as one variable increases, the other does too.
> - **Negative Correlation:** If one line moves up while the other moves down, it indicates a negative correlation, showing that an increase in one variable corresponds to a decrease in the other.
> - **No Correlation:** If the lines do not follow a consistent pattern, it suggests there is no discernible relationship between the variables.
>
> This visualization helps you understand how the datasets interact and influence each other over time, revealing potential trends and relationships.
`;


// Register Chart.js components
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

// Function to calculate the linear regression
const linearRegression = (x, y) => {
    // Filter out null values and ensure both arrays are of the same length
    const cleanedData = [];
    for (let i = 0; i < x.length; i++) {
        if (x[i] !== null && y[i] !== null) {
            cleanedData.push([x[i], y[i]]);
        }
    }

    // Separate the cleaned data back into x and y arrays
    const filteredX = cleanedData.map(data => data[0]);
    const filteredY = cleanedData.map(data => data[1]);

    const n = filteredX.length;  // Number of data points
    if (n === 0) {
        throw new Error("No valid data points available for regression.");
    }

    const xMean = filteredX.reduce((a, b) => a + b) / n;  // Mean of x values
    const yMean = filteredY.reduce((a, b) => a + b) / n;  // Mean of y values

    // Calculate the numerator for the slope
    const numerator = filteredX.reduce((acc, val, idx) => acc + (val - xMean) * (filteredY[idx] - yMean), 0);
    // Calculate the denominator for the slope
    const denominator = filteredX.reduce((acc, val) => acc + (val - xMean) ** 2, 0);

    // Calculate slope and intercept
    const slope = numerator / denominator;  // Slope of the regression line
    const intercept = yMean - slope * xMean;  // Intercept of the regression line

    return { slope, intercept };  // Return slope and intercept as an object
};

const Chart = ({ chartData, neverShowFeedback, setNeverShowFeedback, setShowFeedbackModal, setIsFeedbackPromptVisible }) => {
    const [range, setRange] = useState([0, 0]);
    const [chartType, setChartType] = useState('scatter'); // Default chart type

    // Update range when chartData is available
    useEffect(() => {
        if (chartData && chartData.labels) {
            setRange([0, chartData.labels.length - 1]); // Set left slider to 0 and right to max
        }
    }, [chartData]);

    // Feedback handler for the Chart component
    const handleChartAction = useCallback(() => {
        // Only show feedback modal if the user hasn't opted out
        if (!neverShowFeedback) {
            const randomChance = Math.random();
            if (randomChance < 0.2) {
                setShowFeedbackModal(true);
                setIsFeedbackPromptVisible(true);
            }
        }
    }, [neverShowFeedback, setShowFeedbackModal, setIsFeedbackPromptVisible, setNeverShowFeedback]);

    // Function to handle chart type change
    const handleChartTypeChange = () => {
        const newType = chartType === 'line' ? 'scatter' : 'line';
        setChartType(newType);
        handleChartAction(); // Trigger feedback logic when changing the chart type
    };

    // Check if chartData and labels exist to prevent errors
    if (!chartData || !chartData.labels) {
        return <div>Loading data...</div>;
    }

    // Filter the data based on the selected range
    const filteredLabels = chartData.labels.slice(range[0], range[1] + 1);
    const filteredVariable1 = chartData.variable1.slice(range[0], range[1] + 1);
    const filteredVariable2 = chartData.variable2.slice(range[0], range[1] + 1);

    // Validate that there is data to perform regression
    if (filteredVariable1.length === 0 || filteredVariable2.length === 0) {
        console.error("Filtered variables contain no valid data points for regression.");
        return <div>No data available for regression.</div>; // Or any other fallback UI
    }

    // Chart data for line chart
    const lineData = {
        labels: filteredLabels,
        datasets: [
            {
                label: chartData.variable1_name || 'Variable 1',
                data: filteredVariable1.map((value, index) => ({
                    x: range[0] + index,
                    y: value,
                })),
                backgroundColor: 'rgba(0, 123, 255, 0.5)',
                borderColor: 'rgba(0, 123, 255, 1)',
                fill: false,
                yAxisID: 'y', // Link to left y-axis
            },
            {
                label: chartData.variable2_name || 'Variable 2',
                data: filteredVariable2.map((value, index) => ({
                    x: range[0] + index,
                    y: value,
                })),
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                borderColor: 'rgba(255, 99, 132, 1)',
                fill: false,
                yAxisID: 'y2', // Link to right y-axis
            },
        ],
    };

    // Define slope and intercept with default values
    let slope = 0;
    let intercept = 0;

    // Calculate regression line for scatter plot
    try {
        ({ slope, intercept } = linearRegression(filteredVariable1, filteredVariable2));
    } catch (error) {
        console.error("Error in linear regression:", error);
        return <div>Error calculating regression.</div>; // Or any other fallback UI
    }

    // Define regression line points
    const regressionLine = [
        { x: Math.min(...filteredVariable1), y: slope * Math.min(...filteredVariable1) + intercept },
        { x: Math.max(...filteredVariable1), y: slope * Math.max(...filteredVariable1) + intercept },
    ];

    // Chart data for scatter chart
    const scatterData = {
        datasets: [
            {
                label: 'Correlation',
                data: filteredVariable1.map((value, index) => ({
                    x: value,
                    y: filteredVariable2[index],
                })),
                backgroundColor: 'rgba(0, 123, 255, 0.5)',
                pointRadius: 5,
            },
            {
                label: 'Regression Line',
                data: regressionLine,
                type: 'line',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 2,
                fill: false,
            },
        ],
    };

    // Calculate min and max for x and y with some margin
    const validVariable1 = filteredVariable1.filter((value) => value !== null && value !== undefined);
    const validVariable2 = filteredVariable2.filter((value) => value !== null && value !== undefined);
    const xMin = Math.min(...validVariable1) - Math.abs(Math.min(...validVariable1)) * 0.05;
    const xMax = Math.max(...validVariable1) + Math.abs(Math.max(...validVariable1)) * 0.05;
    const yMin = Math.min(...validVariable2) - Math.abs(Math.min(...validVariable2)) * 0.05;
    const yMax = Math.max(...validVariable2) + Math.abs(Math.max(...validVariable2)) * 0.05;

    // Determine chart options based on chart type
    const options =
        chartType === 'line'
            ? {
                responsive: true,
                scales: {
                    x: {
                        type: 'linear',
                        position: 'bottom',
                        title: {
                            display: true,
                            text: 'Time',
                        },
                    },
                    y: {
                        type: 'linear',
                        position: 'left',
                        title: {
                            display: true,
                            text: chartData.variable1_name || 'Variable 1',
                        },
                    },
                    y2: {
                        type: 'linear',
                        position: 'right',
                        title: {
                            display: true,
                            text: chartData.variable2_name || 'Variable 2',
                        },
                    },
                },
            }
            : {
                responsive: true,
                scales: {
                    x: {
                        min: xMin,
                        max: xMax,
                        title: {
                            display: true,
                            text: chartData.variable1_name || 'Variable 1',
                        },
                    },
                    y: {
                        min: yMin,
                        max: yMax,
                        title: {
                            display: true,
                            text: chartData.variable2_name || 'Variable 2',
                        },
                    },
                },
            };

    // Render the appropriate chart type
    const renderChart = () => {
        if (chartType === 'line') {
            return <Line data={lineData} options={options} />;
        } else if (chartType === 'scatter') {
            return <Scatter data={scatterData} options={options} />;
        }
    };

    return (
        <div className="chart-container">
            {/* Toggle switch for changing chart types */}
            <div className="chart-type-toggle">
                <span>Scatter</span>
                <label className="switch">
                    <input type="checkbox" checked={chartType === 'line'} onChange={handleChartTypeChange} />
                    <span className="slider"></span>
                </label>
                <span>Line</span>
            </div>

            {/* Render the chart */}
            {renderChart()}
            <div className="range-slider">
                <Range
                    step={1}
                    min={0}
                    max={chartData.labels.length - 1}
                    values={range}
                    onChange={(values) => setRange(values)}
                    renderTrack={({ props, children }) => {
                        const { key, ...rest } = props; // Extract key
                        return (
                            <div
                                {...rest}
                                key={key}
                                style={{
                                    ...rest.style,
                                    height: '6px',
                                    width: '100%',
                                    background: 'linear-gradient(to right, #ddd, #888, #ddd)', // Gray gradient
                                    margin: '20px 0',
                                    borderRadius: '5px',
                                }}
                            >
                                {children}
                            </div>
                        );
                    }}
                    renderThumb={({ props, index }) => {
                        const { key, ...rest } = props; // Extract key
                        return (
                            <div
                                {...rest}
                                key={key}
                                style={{
                                    ...rest.style,
                                    height: '16px',
                                    width: '16px',
                                    backgroundColor: '#888', // Dark gray color for both thumbs
                                    borderRadius: '50%',
                                    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)',
                                    cursor: 'pointer',
                                    // position: 'relative', // Position relative for the label
                                }}
                            >
                                <div className="tooltip">{range[index]}</div>
                                {/* Add a label below each thumb */}
                                <div className="thumb-label">
                                    {index === 0 ? `Start: ${chartData.labels[range[0]]}` : `End: ${chartData.labels[range[1]]}`}
                                </div>
                            </div>
                        );
                    }}
                />
            </div>
            {/* Explanation paragraph */}
            <div className="markdown-container">
                <ReactMarkdown>{HintMarkdown}</ReactMarkdown>
                {chartType === 'line' ? (
                    <ReactMarkdown>{LinePlotExplanationMarkdown}</ReactMarkdown>
                ) : (
                    <ReactMarkdown>{ScatterExplanationMarkdown}</ReactMarkdown>
                )}
            </div>

        </div>
    );
};

export default Chart;
