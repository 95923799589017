import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import './TopCorrelationsTable.css';

const TopCorrelationsTable = ({
    topCorrelations,
    selectedCorrelation,
    onCorrelationClick,
    onTopNChange,
    topN
}) => {
    const [showDescription, setShowDescription] = useState(false);

    const toggleDescription = () => {
        setShowDescription(!showDescription);
    };

    // Function to determine the correlation strength representation
    const getCorrelationBars = (value) => {
        const absoluteValue = Math.abs(value);
        const bars = [];

        // Determine how many bars to create based on the correlation value
        if (absoluteValue < 30) {
            bars.push({ width: '10px', color: value >= 0 ? '#4CAF50' : '#FF6347', height: '10px' }); // Low Strength: 1 small bar
        } else if (absoluteValue < 70) {
            bars.push({ width: '10px', color: value >= 0 ? '#4CAF50' : '#FF6347', height: '10px' }); // Moderate Strength: 1 small bar
            bars.push({ width: '10px', color: value >= 0 ? '#4CAF50' : '#FF6347', height: '20px' }); // Second, larger bar
        } else {
            bars.push({ width: '10px', color: value >= 0 ? '#4CAF50' : '#FF6347', height: '10px' }); // Strong Strength: 1 small bar
            bars.push({ width: '10px', color: value >= 0 ? '#4CAF50' : '#FF6347', height: '20px' }); // Second bar
            bars.push({ width: '10px', color: value >= 0 ? '#4CAF50' : '#FF6347', height: '30px' }); // Third, largest bar
        }

        return bars;
    };

    return (
        <>
            <div className="top-correlations-header-container">
                <h2 className="top-correlations-title">
                    Top Correlations
                    <span className="question-icon" onClick={toggleDescription}>
                        <FontAwesomeIcon icon={faQuestionCircle} />
                    </span>
                </h2>
            </div>

            {/* Conditional rendering for description */}
            {showDescription ? (
                <div className="matrix-description">
                    <div className="top-correlations-description">
                        <p>
                            This table shows the strongest relationships (correlations) between different aspects of your life.
                            A correlation tells you how closely two things are related, ranging from -1 (a strong negative relationship)
                            to +1 (a strong positive relationship). Here's what the different values mean:
                        </p>
                        <ul>
                            <li><strong>-1:</strong> A strong negative correlation. As one variable increases, the other decreases significantly.</li>
                            <li><strong>0:</strong> No noticeable relationship between the two variables.</li>
                            <li><strong>+1:</strong> A strong positive correlation. As one variable increases, the other increases significantly.</li>
                        </ul>

                        <p><strong>How to Interpret Correlation Values:</strong></p>
                        <ul>
                            <li>
                                <strong>0 to 0.3:</strong> Weak positive relationship – these variables are only slightly related. <br />
                                <em>Example:</em> Time spent watching TV and overall happiness might have a weak correlation, meaning more TV time doesn’t significantly impact happiness.
                            </li>
                            <li>
                                <strong>0.3 to 0.7:</strong> Moderate positive relationship – there is a noticeable link. <br />
                                <em>Example:</em> Time spent exercising and overall energy levels could have a moderate correlation, meaning more exercise generally leads to feeling more energetic.
                            </li>
                            <li>
                                <strong>0.7 to 1:</strong> Strong positive relationship – these variables are highly related. <br />
                                <em>Example:</em> The number of hours you sleep and how rested you feel might have a strong correlation, meaning more sleep tends to make you feel significantly more rested.
                            </li>
                            <li>
                                <strong>-0.3 to 0:</strong> Weak negative relationship – a small but negative link between the variables. <br />
                                <em>Example:</em> Social media use and self-esteem could have a weak negative correlation, suggesting slightly more social media might slightly lower self-esteem.
                            </li>
                            <li>
                                <strong>-0.7 to -0.3:</strong> Moderate negative relationship – there’s a noticeable but negative link. <br />
                                <em>Example:</em> Increased work hours and time spent with family could have a moderate negative correlation, where more time at work often reduces time with family.
                            </li>
                            <li>
                                <strong>-1 to -0.7:</strong> Strong negative relationship – a very strong inverse relationship. <br />
                                <em>Example:</em> Stress levels and relaxation time may have a strong negative correlation, meaning more stress is strongly linked to less relaxation.
                            </li>
                        </ul>
                    </div>
                </div>
            ) : (
                <div className="matrix-description"> {/* Same styling as the detailed description */}
                    <div className="top-correlations-description">
                        <p>
                            This table summarizes the strongest correlations between various aspects of your life.
                            Click the question mark icon above for more details.
                        </p>
                    </div>
                </div>
            )}

            <div className="top-correlations-top-n-selector">
                <label htmlFor="topNSelector">Show Top</label>
                <select
                    className="top-correlations-form-select"
                    id="topNSelector"
                    value={topN}
                    onChange={onTopNChange}
                >
                    {[3, 5, 10, 15, 20].map(n => (
                        <option key={n} value={n}>{n}</option>
                    ))}
                </select>
            </div>
            <div className="top-correlations-scrollable-table-container">
                <table className="top-correlations-table">
                    <thead>
                        <tr>
                            <th>Variable 1</th>
                            <th>Variable 2</th>
                            <th>Correlation Value</th>
                            <th>Correlation Strength</th>
                        </tr>
                    </thead>
                    <tbody>
                        {topCorrelations.map((item) => {
                            const bars = getCorrelationBars(item.correlation_value);
                            return (
                                <tr
                                    key={item.id || `${item.variable_1}-${item.variable_2}`}
                                    onClick={() => onCorrelationClick(item)} // Make sure this function sets the selected correlation
                                    className={`top-correlations-clickable-row ${selectedCorrelation === item ? 'top-correlations-selected' : ''}`}
                                >
                                    <td>{item.variable_1}</td>
                                    <td>{item.variable_2}</td>
                                    <td className="correlation-value">{item.correlation_value}%</td>
                                    <td>
                                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                            {/* Render the bars horizontally aligned */}
                                            <div style={{ display: 'flex', alignItems: 'flex-end', gap: '4px' }}>
                                                {bars.map((bar, index) => (
                                                    <div key={index} style={{
                                                        width: bar.width,
                                                        backgroundColor: bar.color,
                                                        height: bar.height,
                                                        borderRadius: '4px',
                                                        marginRight: '0', // Space between bars
                                                        transition: 'height 0.3s ease', // Smooth transition
                                                    }} />
                                                ))}
                                            </div>
                                            {/* Render the text label under the bars */}
                                            <span style={{ marginTop: '4px', textAlign: 'center' }}>
                                                {item.correlation_value >= 70 ? 'Strong Positive' :
                                                    item.correlation_value >= 30 ? 'Moderate Positive' :
                                                        item.correlation_value >= 0 ? 'Low Positive' :
                                                            item.correlation_value >= -30 ? 'Low Negative' :
                                                                item.correlation_value >= -70 ? 'Moderate Negative' : 'Strong Negative'}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </>
    );
};

// Define prop types
TopCorrelationsTable.propTypes = {
    topCorrelations: PropTypes.arrayOf(
        PropTypes.shape({
            variable_1: PropTypes.string.isRequired,
            variable_2: PropTypes.string.isRequired,
            correlation_value: PropTypes.number.isRequired,
            id: PropTypes.string
        })
    ).isRequired,
    selectedCorrelation: PropTypes.object,
    onCorrelationClick: PropTypes.func.isRequired,
    onTopNChange: PropTypes.func.isRequired,
    topN: PropTypes.number.isRequired,
};

export default TopCorrelationsTable;
