import React, { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './UploadPage.css';
import DOMPurify from 'dompurify'; // Import DOMPurify for sanitization
import logo from './assets/images/logo/logo-with-text.svg';
import talkinghead from './assets/images/talking-head.jpg'

const UploadPage = () => {
    const [warningMessage, setWarningMessage] = useState('');
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [randomMessage, setRandomMessage] = useState(''); // State for speech bubble message
    const navigate = useNavigate(); // useNavigate for navigation

    const messages = [
        // Friendly & Inviting
        "Curious how our app works but don't have your own files? Try one of the sample files below!",
        "New here? No problem! See what we can do with one of the sample files below.",

        // Helpful & Reassuring
        "Not sure what to upload? No worries! Test the app with one of our sample files.",
        "No data yet? Explore how our app works by using one of our example files.",

        // Encouraging & Playful
        "Want to give it a spin without your own data? Try a sample file and see the magic!",
        "Ready to explore but don’t have a file? Grab one of our sample files and get started!",

        // Professional & Straightforward
        "No files on hand? Use one of the sample files to see how the app works.",
        "Haven’t uploaded anything yet? Explore the app with one of our sample files.",

        // Engaging & Action-Oriented
        "Need a quick start? Try a sample file and see how the app works for you!",
        "Eager to try the app? Use one of our sample files to get started right away!"
    ];


    // Set a random message when the component mounts
    useEffect(() => {
        const randomIndex = Math.floor(Math.random() * messages.length);
        setRandomMessage(messages[randomIndex]);
    }, []); // Empty dependency array ensures this runs only once on mount

    // Function to check if the file type is invalid
    const isInvalidFileType = useCallback(
        (fileName) => fileName.split('.').pop().toLowerCase() !== 'xlsx',
        []
    );

    // Handle file selection and reset warning message
    const handleFileChange = useCallback((event) => {
        const selectedFile = event.target.files[0];

        if (!selectedFile) {
            setWarningMessage('Please select a file!');
            setFile(null);
            return;
        }

        // Sanitize file name to prevent XSS
        const sanitizedFileName = DOMPurify.sanitize(selectedFile.name);

        if (isInvalidFileType(sanitizedFileName)) {
            setWarningMessage('Invalid file type. Please upload an Excel (.xlsx) file.');
            setFile(null);
            return;
        }

        setFile(selectedFile);
        setWarningMessage(''); // Reset warning message when a valid file is selected
    }, [isInvalidFileType]);

    // Function to upload the selected file
    const uploadFile = useCallback(async () => {
        if (!file) return;

        const formData = new FormData();
        formData.append('file', file);
        setIsLoading(true);

        try {
            const response = await fetch('/api/upload', {
                method: 'POST',
                body: formData,
                credentials: 'include', // Include credentials for cross-origin requests
            });

            if (!response.ok) throw new Error('Failed to upload the file');

            const data = await response.json();

            if (data.error) {
                setWarningMessage(data.error);
            } else {
                const fileId = data.fileId;
                localStorage.setItem('fileId', fileId);
                navigate(`/processing`);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            setWarningMessage('Error uploading file. Please try again.');
        } finally {
            setIsLoading(false);
        }
    }, [file, navigate]);

    // Handle form submission
    const handleSubmit = useCallback(
        (event) => {
            event.preventDefault();

            if (!file) {
                setWarningMessage('Please select a file!');
                return;
            }

            uploadFile();
        },
        [file, uploadFile]
    );

    // Handle navigation back to the home page
    const handleBackClick = () => {
        navigate('/');
    };

    return (
        <div className="upload-page-body">
            <div className="upload-page-container">
                {/* Left section for logo only */}
                <div className="left-section">
                    <img src={logo} alt="App Logo" />
                </div>

                {/* Right section for the upload form */}
                <div className="right-section">
                    <h1 className="upload-page-heading">Upload Your Spreadsheet</h1>
                    <p className="upload-page-paragraph">Please select an Excel (.xlsx) file to analyze</p>
                    <form onSubmit={handleSubmit} encType="multipart/form-data" className="upload-page-form">
                        <input
                            type="file"
                            name="file"
                            accept=".xlsx"
                            onChange={handleFileChange}
                            className="upload-page-file-input"
                        />
                        <button type="submit" className="upload-page-button" disabled={isLoading}>
                            {isLoading ? 'Uploading...' : 'Upload and Process'}
                        </button>
                    </form>
                    {warningMessage && <div className="upload-page-warning-message">{warningMessage}</div>}

                    {/* Back to Home button */}
                    <button className="upload-page-back-button" onClick={handleBackClick}>
                        Back to Home
                    </button>
                </div>
            </div>

            {/* Talking head container with speech bubble */}
            <div className="talking-head-container">
                <div className="talking-head-section">
                    <p className="talking-head-text">{randomMessage}</p>
                    <div className="download-buttons">
                        <a href="/samples/basic_sample.xlsx" className="download-button" download>
                            Basic
                        </a>
                        <a href="/samples/advanced_sample.xlsx" className="download-button" download>
                            Advanced
                        </a>
                    </div>
                </div>
                <img src={talkinghead} alt="Talking Head" className="talking-head-icon" />
            </div>
        </div>
    );
};

export default UploadPage;
